import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogComponent } from './dialog.component';
import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public confirm(
    message: string,
    data?: Array<any>): Observable<any> {
    const modalRef = this.dialog.open(DialogComponent, { data: data, hasBackdrop: true, backdropClass: 'no-backdrop', disableClose: false, width: 'auto', minWidth: 'auto', maxWidth: 'auto', position: { top: '18%', left: '36%' } });
    modalRef.componentInstance.message = message;
    return modalRef.afterClosed();
  }

  openConfirmDialog(msg): Observable<any> {
    const modalRef = this.dialog.open(MatConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      position: { top: "12%" },
      data: {
        message: msg
      },
      //  hasBackdrop: true,
      // backdropClass: 'no-backdrop'
    });
    modalRef.componentInstance.data.message = msg;
    return modalRef.afterClosed();
  }
}
