import { Component } from '@angular/core';
import { NotificationMiddlewareService } from './platform/notificationMiddleware';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Aly';

  constructor(private notificationMiddleware: NotificationMiddlewareService ) {
  }

  ngOnInit(){
    this.notificationMiddleware.init(); 
  }
}
