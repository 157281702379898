import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
@Injectable({
  providedIn: 'root'
})

export class ChangePasswordService {
  private updateNewPasswordURL = 'Account/change-password';
  constructor(private commonService: CommonService) {
  }
  updateNewPassword(data: any) {
    return this.commonService.patch(this.updateNewPasswordURL, data);
  }
}


