<!-- <div class="progress-loader popup" > 
  <div class="loading-spinner popup-content">
    <img src="../../../../assets/img/spinner.gif" loading="lazy"  alt="spinner">
  </div> 
</div> -->


<div id="divLoading" [hidden]="!loading" style="margin: 0px; padding: 0px; position: fixed; right: 0px; top: 0px; width: 100%; height: 100%; background-color: rgb(102, 102, 102); z-index: 30001; opacity: 0.8;">
  <p style="position: absolute; color: White; top: 50%; left: 45%;"> 
  <img height="100" width="100" src="../../../../assets/img/spinner.gif" />
  </p>
  </div>

  