import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserState } from '../services/user-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private userState: UserState,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(state.url.includes('auth') && this.userState.isLoggedIn) {
      this.router.navigateByUrl(this.userState.defaultLoginRedirectUrl);
      return false;
    }

    if(!state.url.includes('auth') && !this.userState.isLoggedIn) {
      const redirect = state.url;
      this.router.navigate(['/auth/login'], {queryParams: {redirect}})
      return false;
    }

    return true;
  }

}
