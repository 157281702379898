import moment from "moment";

export class FilterModel {
  currentPage: number = 1;
  // pageSize: number = 25;
  pageSize: number = 10;
  orderBy: string = '';
  sortOrder: string = '';
  searchText: string = ''
  Id: number = 0;
  value: string = '';
  status: string = '';
  statusType: string = '';
  statusApp: string = '';
  statusTypeApp: string = '';
  hospitalId: number = 0;
  hospitalUserId: number = 0;
  statusIds: number[] = [];
  doctorIds: number[] = [];
  dateFrom: string;
  dateTo: string;
  isActiveShift: string = '';
  dbqPatientId: number = 0;
  doctorSpecialityId: number = 0;
  offset: number = 0;
  isDoctorLogin: boolean = false;
  facilityId: number = 0;
}

export class AuditLogFilterModel {
  currentPage: number = 1;
  pageSize: number = 10;
  orderBy: string = '';
  sortOrder: string = '';
  searchText: string = ''
  dateFrom1: Date;
  dateTo1: Date;
  auditType: number[] = [];
  auditUser: number[] = [];
  tableName: string[] = [];
}

export class ResponseModel {
  data: any = [];
  status: string;
  message: string = ''
  appError: string = ''
  meta: Metadata
}
export class Metadata {
  totalItems: number
  currentPage: number
  pageSize: number
  defaultPageSize: number
  totalPages: number;
  isFirst?:boolean
}
export class OtherOtionModel {
  valueID: number;
  value: string;
}

export interface Pagination {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export class PaginatedResult<T> {
  result: T;
  pagination: Pagination;
}

export class DashboardFilterModel {
  dateFrom: Date;
  dateTo: Date;
  organizationId: number;
  hospitalId: number;
  hospitalProgramIds: number[];
  offset: number;
  dashboardBy: number;

  static init() {
    const tzis = new DashboardFilterModel();
    tzis.dateFrom = new Date(moment().subtract(1, 'M').format("MM/DD/YYYY"));
    tzis.dateTo = new Date(moment().format("MM/DD/YYYY"));
    tzis.hospitalProgramIds = [0];
    tzis.offset = -300;
    tzis.dashboardBy = 2;
    return tzis;
  }
}

export class DashboardDoctor {
  photoThumbnailPath: string;
  doctorName: string;
  totalConsult: string;
  avgAcknowledgeTime: number;
  avgSignOffTime: number;
  avgAcknowledgeTimeLastMonth: number;
  avgSignOffTimeLastMonth: number;
}

