import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import base64 from 'base-64';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, take, tap } from 'rxjs/operators';
import { NotificationMiddlewareService } from 'src/app/platform/notificationMiddleware';
import utf8 from 'utf8';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/common-model';
import { LoginUser } from '../models/loginUser.modal';

@Injectable({
  providedIn: "root"
})
export class CommonService {
  private SytemInfoSubject = new BehaviorSubject<any>({});
  public sytemInfo = this.SytemInfoSubject.asObservable().pipe(distinctUntilChanged());

  public loginUserSubject = new BehaviorSubject<LoginUser>({} as LoginUser);
  public loginUser = this.loginUserSubject.asObservable().pipe(distinctUntilChanged());

  public loginUserRolesSubject = new BehaviorSubject<[]>({} as []);
  public loginUserRoles = this.loginUserRolesSubject.asObservable().pipe(distinctUntilChanged());

  public currentLoginUserInfoSubject = new BehaviorSubject<any>(null);
  public currentLoginUserInfo = this.currentLoginUserInfoSubject.asObservable().pipe(distinctUntilChanged());

  public loginSecuritySubject = new BehaviorSubject<any>({} as object);
  public loginSecurity = this.loginSecuritySubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private loadingStateSubject = new BehaviorSubject<boolean>(false);
  public loadingState = this.loadingStateSubject.asObservable();

  // for update the client side navigations ...
  private updateClientNavigationSubject = new BehaviorSubject<any>(null as number);
  public updateClientNavigation = this.updateClientNavigationSubject.asObservable().pipe(distinctUntilChanged());

  authUserData$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private notificationMiddlewareService: NotificationMiddlewareService
    , private router: Router) {

    SystemIpAddress.then((value) => {
      this.SytemInfoSubject.next({ ipAddress: value });
    }).catch((e) => console.error(e));
  }


  sessionLogout() {
    var returnURL = '';
    if (localStorage.getItem('isDoctor')) { this.purgeAuth(); returnURL = '/web/doctor-login'; }
    else if (localStorage.getItem('isHospital')) { this.purgeAuth(); returnURL = '/web/hospital-login'; }
    else if (localStorage.getItem('isDBQDoctor')) { this.purgeAuth(); returnURL = '/web/dbq-doctor-login'; }
    else if (localStorage.getItem('isSuperAdmin')) { this.purgeSuperAdminAuth(); returnURL = '/webadmin/login'; }
    else {
      this.purgeAuth();
      returnURL = '/web/login';
    }
    this.router.navigate([returnURL]);
    // window.location.reload();
  }

  getAuthenticatedUserData() {
    return this.http.get<any>(`${environment.api_url}/Account/user-by-token`).pipe(
      take(1),
      tap( authUserData => this.authUserData$.next(authUserData)),
      shareReplay(1),
    );
  }

  initializeAuthData() {
    if (localStorage.getItem('access_token')) {
      return this.getAuthenticatedUserData().subscribe({
        next: response => {
          // login successful if there's a jwt token in the response
          if (response && response.token) {
            this.setAuth(response);
          } else {
            this.purgeAuth();
          }
          return response;
        },
        error: error => { }
      });

    }
    else {
      return null;
    }

  }

  isValidFileType(fileName, fileType): boolean {
    // Create an object for all extension lists
    let extentionLists = { video: [], image: [], pdf: [], excel: [], xml: [] };
    let isValidType = false;
    extentionLists.video = ['m4v', 'avi', 'mpg', 'mp4'];
    extentionLists.image = ['jpg', 'jpeg', 'bmp', 'png', 'ico'];
    extentionLists.pdf = ['pdf'];
    extentionLists.excel = ['excel'];
    extentionLists.xml = ['xml'];
    //get the extension of the selected file.
    let fileExtension = fileName.split('.').pop().toLowerCase();
    isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
    return isValidType;
  };

  getUserLoginUrl() {
    if (localStorage.getItem('isDoctor'))
      return '/web/doctor-login';
    else if (localStorage.getItem('isHospital'))
      return '/web/hospital-login';
    else if (localStorage.getItem('isDBQDoctor'))
      return '/web/dbq-doctor-login';
    else if (localStorage.getItem('super-user-token'))
      return '/webadmin/login';
    else
      return '/web/login';
  }

  logout() {
    if (localStorage.getItem('access_token') || localStorage.getItem('super-user-token')) {
      // remove user from local storage to log user out
      this.post(`Account/logout`, {}).subscribe({
        next: response => {
        },
        error: error => { }
      });
    }
    this.purgeAuth()
  }


  postLogout() {
    return this.post(`Account/logout`, {});
  }


  setAuth(response: any) {
    localStorage.setItem('firstTimeLogin', response.data.user.isFirstLogin);
    localStorage.setItem('access_token', JSON.stringify(response.token));
    // Set current user data into observable
    this.loginUserSubject.next(response.data.user);
    this.loginUserRolesSubject.next(response.data.userRoles);

    this.currentLoginUserInfoSubject.next(response.data.user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);

    if (response.data.user.isFirstLogin) {
      this.router.navigate([this.getUserLoginUrl()]);
    }

  }



  purgeAuth() {
    localStorage.removeItem('firstTimeLogin');
    localStorage.removeItem('access_token');
    localStorage.removeItem('super-user-token');
    // Set current user to an empty object
    this.loginUserSubject.next({} as LoginUser);
    this.loginUserRolesSubject.next(null);
    this.currentLoginUserInfoSubject.next(null);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.notificationMiddlewareService.unsubscribeUser();
  }

  initializeSuperAdminAuthData() {
    if (localStorage.getItem('super-user-token')) {
      return this.getAuthenticatedUserData()
        .subscribe(response => {
          // login successful if there's a jwt token in the response
          if (response && response.token) {
            this.setSuperAdminAuth(response);
          } else {
            this.purgeSuperAdminAuth();
          }
          return response;
        });
    }
    return null;
  }

  logoutSuperAdmin() {
    if (localStorage.getItem('access_token') || localStorage.getItem('super-user-token')) {
      // remove user from local storage to log user out
      this.post(`Account/logout`, {}).subscribe({
        next: response => {
        },
        error: error => { }
      });
      this.notificationMiddlewareService.unsubscribeUser();
    }
    this.purgeSuperAdminAuth();
  }

  setSuperAdminAuth(response: any) {
    localStorage.setItem('firstTimeLogin', response.data.user.isFirstLogin);
    localStorage.setItem('super-user-token', JSON.stringify(response.token));
    // Set current user data into observable
    this.loginUserSubject.next(response.data.user);
    this.loginUserRolesSubject.next(response.data.userRoles);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
    if (response.data.user.isFirstLogin) {
      this.router.navigate([this.getUserLoginUrl()]);
    }
  }

  purgeSuperAdminAuth() {
    localStorage.removeItem('firstTimeLogin');
    localStorage.removeItem('super-user-token');
    localStorage.removeItem('access_token');
    // Set current user to an empty object
    this.loginUserSubject.next({} as LoginUser);
    this.loginUserRolesSubject.next(null);
    this.currentLoginUserInfoSubject.next(null);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.notificationMiddlewareService.unsubscribeUser();
  }

  encryptDecryptValue(value: any, isEncrypt: boolean = true): any {
    let response: any;
    if (value != null && value != '') {
      let bytes: any;
      if (isEncrypt) {
        bytes = utf8.encode(value.toString());
        response = base64.encode(bytes);
        //response = CryptoJS.AES.encrypt(JSON.stringify(value), pwd);
      }
      else {
        bytes = base64.decode(value);
        response = utf8.decode(bytes);
        // const bytes = CryptoJS.AES.decrypt(value, pwd);
        // if (bytes.toString()) {
        //   response= JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // }
        //      response = CryptoJS.AES.decrypt(value, pwd);//.toString(CryptoJS.enc.Utf8);
      }
    }
    return response;
  }

  getLoginUserInfo(): LoginUser {
    return this.loginUserSubject.value;
  }

  getSystemIpAddress(): string {
    return this.SytemInfoSubject.value && this.SytemInfoSubject.value.ipAddress;
  }

  updateClientNaviagations(clientId: number) {
    this.updateClientNavigationSubject.next(clientId);
  }

  get getAdditionalHeaders(): string {
    const additionalHeaders = JSON.stringify({
      'Offset': (new Date().getTimezoneOffset()).toString(),
      'Timezone': calculateTimeZone(),
      'IPAddress': this.getSystemIpAddress()
    });
    return additionalHeaders;
  }

  post(url, data): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/${url}`, data);
  }


  postWithParams(url, data, params): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/${url}`, data, {params});
  }

  postFormData(url, data): Observable<any> {
    const additionalHeaders = JSON.stringify({
      'Content-Type': 'multipart/form-data'
    });
    const headers = new HttpHeaders({ additionalHeaders: additionalHeaders });
    return this.http.post<any>(`${environment.api_url}/${url}`, data, { headers: headers });
  }

  get<T>(url: string, params): Observable<T>{
    return this.http.get<T>(`${environment.api_url}/${url}`, {params});
  }

  put(url, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url}/${url}`, data);
  }

  getById(url, data): Observable<any> {
    this.loadingStateSubject.next(true)
    return this.http.get<any>(`${environment.api_url}/${url}`)
      .pipe(map(res => {
        this.loadingStateSubject.next(false);
        return res;
      }));
  }

  getByIdSkipLoader(url, data): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/${url}`, { headers: { 'SkipLoader': '' } })
      .pipe(map(res => {
        return res;
      }));
  }

  getWithoutAuthById(url, data): Observable<any> {
    this.loadingStateSubject.next(true)
    return this.http.get<any>(`${environment.api_url}/${url}`)
      .pipe(map(res => {
        this.loadingStateSubject.next(false);
        return res;
      }));
  }

  getAllData(url): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api_url}/${url}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllDataSkipLoader(url): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api_url}/${url}`, { headers: { 'SkipLoader': '' } })
      .pipe(map(res => {
        return res;
      }));
  }

  getAuditLogData(url, page?, pageSize?, searchText?, sortOrder?, orderBy?, auditType?, auditUser?, tableName?, dateFrom1?, dateTo1?): Observable<PaginatedResult<any[]>> {
    const paginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();
    let params = new HttpParams();
    if (localStorage.getItem('isAdmin')) {
      params = params.append('offset', moment().tz(environment.admin_TimeZone).utcOffset());
    }
    else
      params = params.append('offset', moment(new Date()).local().utcOffset());

    if (page != null && pageSize != null) {
      params = params.append('currentPage', page);
      params = params.append('pageSize', pageSize);
    }
    if (sortOrder != null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (orderBy != null) {
      params = params.append('orderBy', orderBy);
    }
    //if (auditType != null) {
    //  params = params.append('auditType', auditType);
    //  console.log(auditType);
    //}
    if (auditType != null && Array.isArray(auditType)) {
      auditType.forEach(type => {
        params = params.append('auditType', type.toString());
      });
    }

    //if (auditUser != null) {
    //  params = params.append('auditUser', auditUser);
    //  console.log(auditUser);
    //}
    if (auditUser != null && Array.isArray(auditUser)) {
      auditUser.forEach(user => {
        params = params.append('auditUser', user.toString());
      });
    }
    if (tableName != null && Array.isArray(tableName)) {
      tableName.forEach(name => {
        params = params.append('tableName', name);
      });
    } else if (tableName != null) {
      params = params.set('tableName', tableName);
    }
    if (dateFrom1 != null) {
      params = params.append('dateFrom1', dateFrom1);
      console.log(dateFrom1);
    }
    if (dateTo1 != null) {
      params = params.append('dateTo1', dateTo1);
      console.log(dateTo1);
    }

    return this.http
      .get<any[]>(`${environment.api_url}/${url}`, { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  getAll(url, page?, pageSize?, searchText?, sortOrder?, orderBy?, status?, statusType?, hospitalId?, statusIds?, doctorIds?, dateFrom?, dateTo?, isActiveShift?, hospitalUserId?, dbqPatientId?, doctorSpecialityId?, isDoctorLogin?, statusApp?, statusTypeApp?): Observable<PaginatedResult<any[]>> {
    const paginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();
    let params = new HttpParams();
    if (localStorage.getItem('isAdmin')) {
      params = params.append('offset', moment().tz(environment.admin_TimeZone).utcOffset());
    }
    else
      params = params.append('offset', moment(new Date()).local().utcOffset());

    if (page != null && pageSize != null) {
      params = params.append('currentPage', page);
      params = params.append('pageSize', pageSize);
    }
    if (searchText != null) {
      params = params.append('searchText', searchText);
    }
    if (sortOrder != null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (orderBy != null) {
      params = params.append('orderBy', orderBy);
    }
    if (status != null) {
      params = params.append('status', status);
    }
    if (statusType != null) {
      params = params.append('statusType', statusType);
    }
    if (statusApp != null) {
      params = params.append('statusApp', statusApp);
    }
    if (statusTypeApp != null) {
      params = params.append('statusTypeApp', statusTypeApp);
    }
    if (hospitalId != null) {
      params = params.append('hospitalId', hospitalId);
    }
    if (statusIds != null && statusIds.length > 0) {
      params = params.append('statusIds', statusIds.join(', '));
    }
    if (doctorIds != null && doctorIds.length > 0) {
      params = params.append('doctorIds', doctorIds.join(', '));
    }
    if (dateFrom != null) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo != null) {
      params = params.append('dateTo', dateTo);
    }
    if (isActiveShift != null) {
      params = params.append('isActiveShift', isActiveShift);
    }
    if (hospitalUserId != null) {
      params = params.append('hospitalUserId', hospitalUserId);
    }
    if (dbqPatientId != null) {
      params = params.append('dbqPatientId', dbqPatientId);
    }
    if (doctorSpecialityId != null) {
      params = params.append('doctorSpecialityId', doctorSpecialityId);
    }
    if (isDoctorLogin != null) {
      params = params.append('isDoctorLogin', isDoctorLogin);
    }

    return this.http
      .get<any[]>(`${environment.api_url}/${url}`, { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  getAllSkipLoader(url, page?, pageSize?, searchText?, sortOrder?, orderBy?, status?, statusType?, hospitalId?, statusIds?, doctorIds?, dateFrom?, dateTo?, isActiveShift?, hospitalUserId?, dbqPatientId?, doctorSpecialityId?, isDoctorLogin?, statusApp?, statusTypeApp?): Observable<PaginatedResult<any[]>> {
    const paginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();
    let params = new HttpParams();
    if (localStorage.getItem('isAdmin')) {
      params = params.append('offset', moment().tz(environment.admin_TimeZone).utcOffset());
    }
    else
      params = params.append('offset', moment(new Date()).local().utcOffset());

    if (page != null && pageSize != null) {
      params = params.append('currentPage', page);
      params = params.append('pageSize', pageSize);
    }
    if (searchText != null) {
      params = params.append('searchText', searchText);
    }
    if (sortOrder != null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (orderBy != null) {
      params = params.append('orderBy', orderBy);
    }
    if (status != null) {
      params = params.append('status', status);
    }
    if (statusType != null) {
      params = params.append('statusType', statusType);
    }
    if (statusApp != null) {
      params = params.append('statusApp', statusApp);
    }
    if (statusTypeApp != null) {
      params = params.append('statusTypeApp', statusTypeApp);
    }
    if (hospitalId != null) {
      params = params.append('hospitalId', hospitalId);
    }
    if (statusIds != null && statusIds.length > 0) {
      params = params.append('statusIds', statusIds.join(', '));
    }
    if (doctorIds != null && doctorIds.length > 0) {
      params = params.append('doctorIds', doctorIds.join(', '));
    }
    if (dateFrom != null) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo != null) {
      params = params.append('dateTo', dateTo);
    }
    if (isActiveShift != null) {
      params = params.append('isActiveShift', isActiveShift);
    }
    if (hospitalUserId != null) {
      params = params.append('hospitalUserId', hospitalUserId);
    }
    if (dbqPatientId != null) {
      params = params.append('dbqPatientId', dbqPatientId);
    }
    if (doctorSpecialityId != null) {
      params = params.append('doctorSpecialityId', doctorSpecialityId);
    }
    if (isDoctorLogin != null) {
      params = params.append('isDoctorLogin', isDoctorLogin);
    }
    return this.http
      .get<any[]>(`${environment.api_url}/${url}`, { headers: { 'SkipLoader': '' }, observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  delete(url, data): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/${url}`);
  }

  deleteItem<T>(url, params): Observable<T> {
    return this.http.delete<T>(`${environment.api_url}/${url}`, {params})
  }

  patch(url, data): Observable<any> {
    this.loadingStateSubject.next(true)
    return this.http.patch<any>(`${environment.api_url}/${url}`, data)
      .pipe(map(res => {
        this.loadingStateSubject.next(false);
        return res;
      }));
  }

  getDashboardData(url, dateFrom, dateTo, hospitalIds, hospitalProgramIds, dashboardBy): Observable<any[]> {
    var result: any[];
    let params = new HttpParams();
    if (localStorage.getItem('isAdmin')) {
      params = params.append('offset', moment().tz(environment.admin_TimeZone).utcOffset());
    }
    else
      params = params.append('offset', moment(new Date()).local().utcOffset());

    if (hospitalIds != null) {
      //var stringHospitalIds = hospitalIds.join().split(',');
      params = params.append('hospitalIds', hospitalIds.toString());
    } else {
      params = params.append('hospitalIds', '0')
    };

    if (dateFrom != null) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo != null) {
      params = params.append('dateTo', dateTo);
    }
    if (hospitalProgramIds != null) {
      var stringHospitalProgramIds = hospitalProgramIds.join().split(',');
      params = params.append('hospitalProgramIds', stringHospitalProgramIds);
    } else {
      params = params.append('hospitalProgramIds', '0')
    };
    if (dashboardBy != null) {
      params = params.append('dashboardBy', dashboardBy);
    }

    return this.http
      .get<any[]>(`${environment.api_url}/${url}`, { observe: 'response', params })
      .pipe(
        map(response => {
          result = response.body;
          return result;
        })
      );
  }


  download(url): Observable<Blob> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get(`${environment.api_url}/${url}/download-file`, { headers, responseType: 'blob' });
  }

  //downloadFile(): Observable<HttpEvent<Blob>> {
  //  return this.http.request(new HttpRequest(
  //    'GET',
  //    `http://localhost:44434/`,
  //    null,
  //    {
  //      reportProgress: true,
  //      responseType: 'blob'
  //    }));
  //}

  getUserTZDateTime(dateTime: Date) {
    var cstStartDate = moment.utc(dateTime).utcOffset(moment().tz(environment.admin_TimeZone).utcOffset());
    dateTime = new Date(cstStartDate.year(), cstStartDate.month(), cstStartDate.date(), cstStartDate.hour(), cstStartDate.minute(), cstStartDate.second());
    return dateTime;
  }

}


const SystemIpAddress = new Promise((r) => {
  const w: any = window, a = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({ iceServers: [] }), b = () => { }; a.createDataChannel(''); a.createOffer((c) => a.setLocalDescription(c, b, b), b); a.onicecandidate = (c) => {
    try {
      c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r);
    } catch (e) { }
  };
});

function calculateTimeZone(dateInput?: Date): string {
  var dateObject = dateInput || new Date(),
    dateString = dateObject + '',
    tzAbbr: any = (
      // Works for the majority of modern browsers
      dateString.match(/\(([^\)]+)\)$/) ||
      // IE outputs date strings in a different format:
      dateString.match(/([A-Z]+) [\d]{4}$/)
    );

  if (tzAbbr) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    tzAbbr = tzAbbr[1];
  }
  return tzAbbr;
};

