import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DataTableComponent } from './data-table/data-table.component';
import { CardNumberDirective } from './directives/card-number.directive';
import { MrnNumberDirective } from './directives/mrn-number.directive';
import { NpiNumberDirective } from './directives/npi-number.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { RateDirective } from './directives/rate.directive';
import { SsnDirective } from './directives/ssn.directive';
import { ZipcodeDirective } from './directives/zipcode.directive';
import { DialogComponent } from './layout/dialog/dialog.component';
import { DialogService } from './layout/dialog/dialog.service';
import { FooterComponent } from './layout/footer/footer.component';
import { MatConfirmDialogComponent } from './layout/mat-confirm-dialog/mat-confirm-dialog.component';
import { MenuListItemComponent } from './layout/menu-list-item/menu-list-item.component';
import { sanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { SpanPipe } from './pipes/span.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { dateFormat } from './pipes/dateFormat.pipe';
import { ChangePasswordService } from './services/change-password.service';
import { SharedService } from './shared.service';
import { LoaderService } from '../shared/services/loader.service';
import { LoaderInterceptor } from './services/interceptors/loader-interceptor.service';
import { LoaderComponent } from './layout/loader/loader.component';
import { DataTableScorllComponent } from './data-table-scroll/data-table-scroll.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SideBarComponent } from './sidebar/sidebar.component';
import { InputClearableComponent } from './inputs/input-clearable/input-clearable.component';
import { ChatPopupComponent, ChatUploadFileComponent } from './chat-popup/chat-popup.component';
import { DropdownBtnComponent } from './dropdown-btn/dropdown-btn.component';
import { JCollapseComponent } from './j-collapse/j-collapse.component';
import { TabHeadComponent } from './tab-head/tab-head.component';
import { dateDiffirence } from './pipes/date-difference.pipe';
import { DroppableFileDirective } from './directives/droppable-file.directive';
import { PermissionDirective } from '../platform/permission.directive';
import { DateUtcToLocalPipe } from './pipes/date-utc-to-local.pipe';
import { GenericHttpInterceptor } from '../core/interceptors/generic-http.interceptor';
import { DurationPipe } from './pipes/duration.pipe';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { ButtonStateComponent } from './button-state/button-state.component';
import { RemainingTimeDirective } from './directives/remaining-time.directive';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';



@NgModule({
  imports: [
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    HttpClientModule,
    NgxGaugeModule,
    NgxChartsModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  declarations: [
    sanitizeHtmlPipe,
    FooterComponent,
    MenuListItemComponent,
    DataTableComponent,
    DataTableScorllComponent,
    DialogComponent,
    NumbersOnlyDirective,
    StatusPipe,
    PhoneNumberDirective,
    NpiNumberDirective,
    SsnDirective,
    ZipcodeDirective,
    RateDirective,
    MrnNumberDirective,
    SpanPipe,
    CardNumberDirective,
    MatConfirmDialogComponent,
    LoaderComponent,
    dateFormat,
    dateDiffirence,
    SideBarComponent,
    InputClearableComponent,
    ChatPopupComponent,
    ChatUploadFileComponent,
    DropdownBtnComponent,
    JCollapseComponent,
    TabHeadComponent,
    ButtonStateComponent,
    CountdownTimerComponent,

    DroppableFileDirective,
    PermissionDirective,
    DateUtcToLocalPipe,
    DurationPipe,
    DateFormatterPipe,
    RemainingTimeDirective
  ],
  providers: [
    SharedService,
    ChangePasswordService,
    DialogService,
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: GenericHttpInterceptor, multi: true },
  ],
  entryComponents: [DialogComponent],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    FooterComponent,
    MatTableModule,
    MatSortModule,

    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    DataTableComponent,
    DataTableScorllComponent,
    DialogComponent,
    NumbersOnlyDirective,
    StatusPipe,
    PhoneNumberDirective,
    NpiNumberDirective,
    ZipcodeDirective,
    SsnDirective,
    MrnNumberDirective,
    MatSelectModule,
    CardNumberDirective,
    LoaderComponent,
    dateFormat,
    NgxGaugeModule,
    NgxChartsModule,
    SideBarComponent,
    InputClearableComponent,
    ChatPopupComponent,
    DropdownBtnComponent,
    JCollapseComponent,
    ButtonStateComponent,
    TabHeadComponent,
    PermissionDirective,
    DurationPipe,
    DateFormatterPipe,
    CountdownTimerComponent
  ]
})
export class SharedModule { }
