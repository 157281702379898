import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    // Log the error to the console or send it to your server
    console.error('An error occurred:', error);
    // Capture the error with Sentry
    Sentry.captureException(error);

    // Optional: Customize error handling
    if (error instanceof HttpErrorResponse) {
      // Handle HTTP error
      console.error(' Catch Global: API Error Handler >>>> ', error, error)
    } else {
      console.error(' Catch Global: Client Error Handler >>>> ', error, 'STACK : >>>', error.stack)
    }
  }
}
