// import { Injectable } from '@angular/core';
// import { Subject } from 'rxjs';


// @Injectable({
//   providedIn: 'root'
// })
// export class LoaderService {
//   isLoading = new Subject<boolean>();
//   show() {
//     this.isLoading.next(true);
//   }
//   hide() {
//     this.isLoading.next(false);
//   }
// }


import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class LoaderService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ShowLoader() {
    this.isLoading.next(true);
  }

  HideLoader() {
    this.isLoading.next(false);
  }
}

