import {JwtPayload, jwtDecode} from 'jwt-decode';

export class JwTokenHandler {

  static retreivePermissionCodesFromPayload(payload: JwtPayload){
    return Object.keys(payload).filter( p => p.split('').every(char => char === char.toUpperCase()));
  }

  static getClaims(token: string) {
    const decodedToken: JwtPayload = jwtDecode(JwTokenHandler.Token);
    return JwTokenHandler.retreivePermissionCodesFromPayload(decodedToken);
  }

  static getValue(key: string) {
    return JwTokenHandler.getValueByKey(JwTokenHandler.Token, key);
  }

  static get Token(): null | string {
    return localStorage.getItem('access_token');
  }

  static getPayload(token: string) {
    return jwtDecode(token);
  }

  static userHasPermission(userPermissions, allowedPermissions): boolean {
    if (!allowedPermissions)
      return true;

    for (const permission of allowedPermissions)
      if (userPermissions.includes(permission))
        return true;

    return false;
  }

  static getValueByKey(token:string, key:string) {
    const decodedToken: JwtPayload = jwtDecode(token)
    if(Object.keys(decodedToken).includes(key))
      return decodedToken[key];
    return null;
  }

}
