import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { LoaderInterceptor } from './shared/services/interceptors/loader-interceptor.service';

import { CommonService } from './shared/services/common.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotificationService } from './platform/generated';
import { ChatService } from './shared/services/chat/chat.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    LoadingBarModule,
    SharedModule,
    GoogleMapsModule,
    MatSnackBarModule,
  ],
  providers: [
    CommonService,
    NotificationService,
    // ChatService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: LoaderInterceptor,
    //  multi: true
    //}
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
