import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { applicationUser, loginResponse } from './authentication.service';
import { JwTokenHandler } from 'src/app/shared/services/jw-token-handler.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class UserState {
  private _currentUserSub$ = new BehaviorSubject<applicationUser>(null);
  private _userTypeSub$ = new BehaviorSubject<UserType | null>(null);
  private _token: string | null;

  public get token() {
    if (this.isLoggedIn) return this._token;
    return null;
  }

  isLoggedIn = false;

  currentUser$ = this._currentUserSub$.asObservable();

  userType$ = this._userTypeSub$.pipe(
    map((userTypeId) => UserType[userTypeId])
  );

  setTimeOutExpired;

  userLevel$ = this._userTypeSub$.pipe(
    map((userTypeId) => {
      if (userTypeId == UserType.SuperAdmin) return UserLevel.SuperAdminLevel;

      if (
        userTypeId == UserType.CompanyAdmin ||
        userTypeId == UserType.CompanyStaff
      )
        return UserLevel.CompanyLevel;

      if (
        userTypeId == UserType.HospitalAdmin ||
        userTypeId == UserType.HospitalStaff
      )
        return UserLevel.HospitalLevel;

      return UserLevel.DoctorLevel;
    })
  );

  claims: Array<string> = [];

  defaultLoginRedirectUrl = '/consults';

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private commonSrv: CommonService
  ) {
    this.initStateFromStorage();
  }

  initState(login: loginResponse) {
    localStorage.setItem('user', JSON.stringify(login.data.user));
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('access_token', login.token);
    this._currentUserSub$.next(login.data.user);
    this._token = login.token;
    this._userTypeSub$.next(
      JwTokenHandler.getValueByKey(this._token, 'UserTypeId') as UserType
    );
    this.claims = JwTokenHandler.getClaims(this._token);
    this.isLoggedIn = true;
    this._isTokenExpired(this._token);
  }

  initStateFromStorage() {
    this.isLoggedIn = localStorage.getItem('isLoggedIn') == 'true';
    this._token = localStorage.getItem('access_token');

    if (this._token == null || !this.isLoggedIn) return;

    if (this._isTokenExpired(this._token)) {
      this.clearState();
      const redirect = this.actRoute.url;
      this.isLoggedIn = false;
      this.router.navigate(['/auth/login'], { queryParams: { redirect } });
    }

    if (this.isLoggedIn) {
      this._currentUserSub$.next(
        JSON.parse(localStorage.getItem('user')) as applicationUser
      );
      this._userTypeSub$.next(
        JwTokenHandler.getValueByKey(this._token, 'UserTypeId') as UserType
      );
      this.claims = JwTokenHandler.getClaims(this._token);
      console.log(' >>>>> this.claims ', this.claims);

      this.isLoggedIn = true;
    }
  }

  private _isTokenExpired(token: string) {
    // if(token ===null || token === "" || !this.isLoggedIn){

    //   this._showSessionExpiredPopup();

    //    return true;
    // }

    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    const now = Math.floor(new Date().getTime() / 1000);
    const diff = (expiry - now) * 1000;

    if (now > expiry) return true;

    clearTimeout(this.setTimeOutExpired);

    this.setTimeOutExpired = setTimeout(() => {
      this.clearState();
      this._showSessionExpiredPopup();
    }, diff);

    return false;
  }

  _showSessionExpiredPopup() {
    Swal.fire({
      title: 'Your session has expired. You will need to login again',
      confirmButtonText: 'Okay',
      confirmButtonColor: 'rgb(8 145 178 / var(--tw-bg-opacity))',
    }).then((result) => {
      this.clearState();
      this.router.navigateByUrl('/auth/login');
    });
  }

  clearState() {
      console.log('LOGOUT: SUCCESS');
      this._currentUserSub$.next(null);
      this._token = null;
      this._userTypeSub$.next(null);
      this.isLoggedIn = false;
      this.claims = [];
      localStorage.clear();
      clearTimeout(this.setTimeOutExpired);
  }

  logout(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.commonSrv.postLogout().subscribe(
        (_) => {
          this.clearState();
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

}

export enum UserLevel {
  SuperAdminLevel = 1,
  CompanyLevel = 2,
  HospitalLevel = 3,
  DoctorLevel = 4,
}

export enum UserType {
  //superAdmin
  SuperAdmin = 1,
  //company level
  CompanyAdmin = 2,
  CompanyStaff = 3,
  //hospital level
  HospitalAdmin = 4,
  HospitalStaff = 5,
  //doctorlevel
  Doctor = 6,
  DBQDoctor = 7,
  DBQPatient = 8,
}
