import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, of, throwError} from 'rxjs';
import { UserState } from 'src/app/auth/services/user-state.service';
import { NotificationService } from 'src/app/shared/layout/Toaster/NotificationService';

// # we use this interceptor
@Injectable()
export class GenericHttpInterceptor implements HttpInterceptor {

  constructor(
    private userState: UserState,
    private notificationSrv: NotificationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(this.userState.isLoggedIn) {
      const authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.userState.token}`,
        },
      });

      return this.handleError(next.handle(authRequest));
    }

    return this.handleError(next.handle(request));
  }

  private handleError(req: Observable<HttpEvent<any>>): Observable<any> {
    return req.pipe(
      catchError( err => {

        if (err instanceof HttpErrorResponse && err.status === 401 && this.userState.token) {
          this.userState._showSessionExpiredPopup();
          return of(err as any)
        }

        if (err instanceof HttpErrorResponse && err.status === 500) {
          console.log(err);

          return of(err as any);
        }

        return throwError(err);
        //return of(err);
      })
    );
  }

}
